import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.section`
	background: transparent;
	padding: 1rem 0 3rem 0;
	border-radius: 1px;

	.article__author-info {
		background: transparent;
		column-gap: 1rem;
	}

	.author-image {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		background: #ddd;
		background: linear-gradient(134deg, #f6f6f6, #f6f5f6 45%, rgba(0, 0, 0, 0.25) 130%, #f6f6f6 0, #f6f6f6);
		background: url(https://assets.thenewhumanitarian.org/s3fs-public/styles/author/public/2024-02/ZakirHossainChowdhury.jpg.webp?itok=rVZ2aRTR);
	}

	.author-info__name {
		border: none !important;
		font-size: 1.8rem !important;
	}

	.author-info__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 0;

		p {
			padding: 0;
			font-size: 1.6rem;

			a:hover {
				background: transparent !important;
				text-decoration: underline;
			}
		}

		.author-info__bio {
			font-size: 1.6rem;
		}
	}

	.author-info__text a span {
		font-weight: bold !important;
		color: #9f3e52;
		font-family: Roboto, sans-serif;
	}

	.ss__item--twitter {
		font-size: 100%;
		background-image: url('https://www.thenewhumanitarian.org/sites/all/themes/thenewhumanitarian/images/icon-twitter.svg');
		border: none !important;
		display: inline-block !important;
		background-repeat: no-repeat;
		background-size: 70%;

		&:hover {
			background-color: transparent !important;
			background-repeat: no-repeat;
			background-size: 70%;
			background-position: center;
			background-image: url('https://www.thenewhumanitarian.org/sites/all/themes/thenewhumanitarian/images/icon-twitter.svg');
			color: black;
		}
	}
`

const AuthorInfoComponent = (props) => {
	return (
		<StyledSection>
			<div className='article__authors' style={{ margin: 0 }}>
				<div className='article__author-info'>
					<div className='author-image' />
					<div className='author-info__text'>
						<p>
							<a
								href='https://www.thenewhumanitarian.org/authors/zakir-hossain-chowdhury'
								title='See articles by Zakir Hossain Chowdhury'
								className='author-info__name'
							>
								<span itemProp='author'>Zakir Hossain Chowdhury</span>
							</a>
							<a
								className='ss__item ss__item--twitter'
								title='Twitter profile of Zakir Hossain Chowdhury'
								rel='noopener noreferrer'
								href='https://www.twitter.com/auni_auniket'
								target='_blank'
							>
								&nbsp;
							</a>
						</p>
						<p className={'author-info__bio'} style={{ marginTop: 0 }}>
							Dhaka-based photojournalist covering social issues, climate change, and refugees.
						</p>
					</div>
				</div>
			</div>
		</StyledSection>
	)
}

export default AuthorInfoComponent
