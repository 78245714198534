import React from 'react'

import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const ScrollySection = styled.section`
  .scrolly {
    max-width: 100vw;
    margin: 0 auto;
    z-index: 999;
    background-color: ${(props) => props.theme.colors.lightGrey};

    .gatsby-image-wrapper {
      position: relative;
      z-index: 0;

      *,
      img {
        z-index: 1 !important;
      }
    }

    figure.sticky {
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      display: block;
      height: ${(props) => props.height || '100vh'};
      min-height: -webkit-fill-available;
      background: transparent;
      overflow: hidden;
      margin: 0;
      top: 0;
      left: 0;
      z-index: 1 !important;

      .sticky-image--background {
        height: 100svh;
        min-height: 100svh;
        z-index: -1;
      }

      .sticky-image--foreground {
        min-height: 100svh;
        position: relative;
        width: 100%;
        top: 0;
        z-index: 1;
        display: block;

        img {
          object-fit: cover !important;
          -webkit-box-shadow: 0px 0px 51px 6px rgba(255, 255, 255, 0.8);
          -moz-box-shadow: 0px 0px 51px 6px rgba(255, 255, 255, 0.8);
          box-shadow: 0px 0px 51px 6px rgba(255, 255, 255, 0.8);
        }

        @media screen and (max-width: 500px) {
          img {
            object-fit: contain !important;
          }
        }
      }
    }

    article {
      position: -webkit-sticky;
      position: sticky;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      padding: 0;
      width: 90%;
      margin: 0 auto;
      z-index: 999;
      max-width: 90rem;
      margin: 0 auto;

      .step {
        min-height: 80vh;
        z-index: 999;
        position: relative;
        max-width: 70rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;

        > * {
          border-radius: 1px;
        }

        h1 {
          font-family: ${(props) => props.theme.fonts.serif};
          color: ${(props) => props.theme.colors.burgundy};
          background-color: ${(props) => props.theme.colors.transparentWhite};
          text-align: left;
          padding: 1rem;
          margin: 0;
        }

        p {
          margin: 0;
          padding: 1rem;
          text-align: left;
          font-weight: 400;
          font-family: ${(props) => props.theme.fonts.sans};
          background-color: ${(props) => props.theme.colors.transparentWhite};
          -webkit-transition: background-color 250ms ease-in-out;
          transition: background-color 250ms ease-in-out;
          color: ${(props) => props.theme.colors.text};
        }

        a {
          font-weight: 500;
          text-decoration: none;

          &:hover {
            text-decoration: #9f3e52 underline;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`

const ScrollImage = (props) => {
  // console.log(props.minHeight)

  return (
    <ScrollySection>
      <div className='scrolly'>
        {props.image && (
          <figure className='sticky'>
            <div style={{ position: 'relative' }}>
              <GatsbyImage
                alt={'An image.'}
                style={{
                  ...props.imgStyles,
                  minHeight: props.height || '100vh',
                }}
                imgStyle={{
                  filter: 'blur(8px)',
                  transform: 'scale(1.15)',
                }}
                image={{
                  ...props.image,
                }}
                className='sticky-image--background'
              />
              <GatsbyImage
                alt={'An image.'}
                style={{
                  ...props.imgStyles,
                  minHeight: props.height || '100vh',
                  position: 'absolute',
                }}
                imgStyle={{
                  objectPosition: '50% 50%',
                }}
                image={{
                  ...props.image,
                }}
                className='sticky-image--foreground'
              />
            </div>
          </figure>
        )}
        <article
          style={{
            minHeight: props.minHeight || 'unset',
          }}
        >
          {props.children}
        </article>
      </div>
    </ScrollySection>
  )
}

export default ScrollImage
