import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import 'mapbox-gl/dist/mapbox-gl.css'

import ReactMapGL, { FlyToInterpolator, Source, Layer } from 'react-map-gl'

// import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

import 'styles/animations.css'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const mapStyle = require('@data/map-basic-styles.js')

const ScrollySection = styled.section`
	max-width: 100vw;
	margin: 0 auto;
	background-color: ${(props) => props.theme.colors.lightGrey};

	figure.sticky {
		position: -webkit-sticky;
		position: sticky;
		width: 100%;
		height: ${(props) => props.height || '100vh'};
		min-height: -webkit-fill-available;
		background: transparent;
		overflow: hidden;
		margin: 0;
		top: 0;
		left: 0;
		z-index: 1 !important;

		.mapboxgl-map {
			z-index: 1 !important;
		}
	}

	article {
		padding: 0;
		width: 90%;
		margin: 0 auto;
		position: -webkit-sticky;
		position: sticky;
		z-index: 999;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		max-width: 90rem;
		margin: 0 auto;

		.step {
			min-height: 80vh;
			margin-bottom: 1rem;
			max-width: 70rem;
			margin-left: auto;
			margin-right: auto;

			> * {
				border-radius: 1px;
			}

			h1 {
				font-family: ${(props) => props.theme.fonts.serif};
				color: ${(props) => props.theme.colors.burgundy};
				background-color: ${(props) => props.theme.colors.transparentWhite};
				text-align: left;
				padding: 1rem;
				margin: 0;
			}

			p {
				margin: 0;
				padding: 1rem;
				text-align: left;
				font-weight: 400;
				font-family: ${(props) => props.theme.fonts.sans};
				background-color: ${(props) => props.theme.colors.transparentWhite};
				-webkit-transition: background-color 250ms ease-in-out;
				transition: background-color 250ms ease-in-out;
				color: ${(props) => props.theme.colors.text};
			}
		}
	}

	max-width: 100vw;
	margin: 0 auto;
	background-color: ${(props) => props.theme.colors.lightGrey};

	figure.sticky {
		position: -webkit-sticky;
		position: sticky;
		width: 100%;
		height: ${(props) => props.height || '100vh'};
		background: transparent;
		overflow: hidden;
		margin: 0;
		top: 0;
		left: 0;
		z-index: 0;
	}

	article {
		padding: 0;
		width: 90%;
		margin: 0 auto;
		position: relative;
		max-width: 90rem;
		margin: 0 auto;
		pointer-events: none;

		.step {
			min-height: 80vh;
			margin-bottom: 1rem;

			a {
				font-weight: 500;
				text-decoration: none;
				color: #9f3e52;

				&:hover {
					text-decoration: #9f3e52 underline;
				}
			}

			> * {
				border-radius: 1px;
				pointer-events: auto;
			}

			h1 {
				font-family: ${(props) => props.theme.fonts.serif};
				color: ${(props) => props.theme.colors.burgundy};
				background-color: ${(props) => props.theme.colors.transparentWhite};
				text-align: left;
				padding: 1rem;
				margin: 0;
			}

			p {
				margin: 0;
				padding: 1rem;
				text-align: left;
				font-weight: 400;
				font-family: ${(props) => props.theme.fonts.sans};
				background-color: ${(props) => props.theme.colors.transparentWhite};
				-webkit-transition: background-color 250ms ease-in-out;
				transition: background-color 250ms ease-in-out;
				color: ${(props) => props.theme.colors.text};
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
`

const InteractionToggle = styled.div`
	z-index: 9999;
	position: absolute;
	top: 10rem;
	right: 1rem;
	pointer-events: auto !important;

	.ui.button {
		background-color: #9f3e52;
		color: ${(props) => props.theme.colors.white};
		border-radius: none;
		font-family: ${(props) => props.theme.fonts.sans};
	}
`

const ScrollMap = (props) => {
	const [mapIsActive, setMapIsActive] = useState(false)
	const [viewport, setViewport] = useState({ ...props.location })

	const _onViewportChange = (viewport) => {
		setViewport({ ...viewport })
	}

	// console.log(props.khulnaData)
	// console.log(props.cycloneData)

	// let _goToNYC

	useEffect(() => {
		const newViewport = {
			...viewport,
			longitude: props.location.lng,
			latitude: props.location.lat,
			zoom: props.location.zoom,
		}
		setViewport(newViewport)
		// eslint-disable-next-line
	}, [props.location])

	return (
		<ScrollySection
			style={{
				...props.style,
			}}
		>
			<figure className={'sticky'}>
				<div style={{ pointerEvents: mapIsActive ? 'auto' : 'none' }}>
					<ReactMapGL
						{...viewport}
						width='100vw'
						height={props.height || '100vh'}
						onViewportChange={(nextViewport) => _onViewportChange(nextViewport)}
						mapStyle={mapStyle}
						transitionDuration={mapIsActive ? 0 : props.transition.duration}
						transitionInterpolator={mapIsActive ? null : new FlyToInterpolator({})}
						mapboxApiAccessToken='pk.eyJ1IjoibXJjZmhyIiwiYSI6ImNrZjZmazNnNzB2emcyeW55cnBka2N0aDcifQ.R6dLET05pSnIXL4H6PKdTA'
					>
						<Source id='khulna' type='geojson' data={props.khulnaData} />
						<Layer
							id='khulna-line-layer'
							type='fill'
							source='khulna'
							paint={{
								'fill-color': '#a03c50',
								// "line-width": 2,
								'fill-opacity': props.showKhulna ? 0.5 : 0,
							}}
						/>
						<Source id='amphan' type='geojson' data={props.cycloneData} />
						<Layer
							id='amphan-line-layer'
							type='line'
							source='amphan'
							paint={{
								'line-color': '#a03c50',
								'line-width': 5,
								'line-opacity': props.showAmphan ? 1 : 0,
							}}
						/>
						<Source id='fani' type='geojson' data={props.faniData} />
						<Layer
							id='fani-line-layer'
							type='line'
							source='fani'
							paint={{
								'line-color': '#2db487',
								'line-width': 5,
								'line-opacity': props.showFani ? 1 : 0,
							}}
						/>
						<Source id='bulbul' type='geojson' data={props.bulbulData} />
						<Layer
							id='bulbul-line-layer'
							type='line'
							source='bulbul'
							paint={{
								'line-color': '#f0781e',
								'line-width': 5,
								'line-opacity': props.showBulbul ? 1 : 0,
							}}
						/>
						{/*
						<Layer
							id="cyclone-fill-layer"
							type="fill"
							source="cyclone"
							paint={{
								"fill-color": "#a03c50",
								"fill-opacity": props.showAmphan ? 0.2 : 0
							}}
						/> */}
						<Source id='bangladeshFlood' type='geojson' data={props.bangladeshFlood} />
						<Layer
							id='bangladesh-line-layer'
							type='line'
							source='bangladeshFlood'
							paint={{
								'line-color': '#413c78',
								'line-width': 2,
								'line-opacity': props.showBangladeshFlood ? 0.5 : 0,
							}}
						/>
					</ReactMapGL>
				</div>
				<InteractionToggle
					style={{
						maxWidth: '8rem',
						textAlign: 'right',
						pointerEvents: 'auto',
					}}
				>
					<button className={'ui big icon button'} onClick={() => setMapIsActive(!mapIsActive)}>
						{mapIsActive ? 'Fixate map' : ' Move map'}
					</button>
				</InteractionToggle>
			</figure>
			<article>{props.children}</article>
		</ScrollySection>
	)
}

export default ScrollMap
